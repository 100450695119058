import "styles/pages/single-post.scss";

import React from "react";
import { graphql } from "gatsby";
import { FacebookShareButton } from "react-share";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import PostAuthor from "components/PostAuthor";
import PostDate from "components/PostDate";
import PostCategories from "components/PostCategories";
import Divider from "components/Divider";
import Breadcrumps from "components/Breadcrumps";
import PostOther from "components/PostOther";

const SinglePost = ({ data: { wpPost } }) => {
  const isBrowser = typeof window !== "undefined";
  const location = isBrowser ? window.location.href : null;

  const seo = wpPost.seo;

  const breadcrumps_data = [
    {
      name: "Blog",
      href: "/blog/",
    },
    {
      name: wpPost.title,
    },
  ];

  console.log(wpPost?.acfPost?.postUpdateDate?.replaceAll("/", "."));

  return (
    <Layout>
      <Seo
        title={seo?.title}
        description={seo?.metaDesc}
        image={wpPost?.featuredImage?.node?.sourceUrl}
      />
      <Breadcrumps data={breadcrumps_data} />
      <SubpageHeader title={wpPost.title} />
      <section className="post-main">
        <div className="container">
          <div className="post-main__info-container">
            <div>
              <PostAuthor
                name={`${wpPost?.author?.node?.firstName} ${wpPost?.author?.node?.lastName}`}
                avatar={wpPost?.author?.node?.avatar?.url}
              />
              <div className="date">
                <PostDate date={wpPost.date} />
                <PostDate
                  date={wpPost?.acfPost?.postUpdateDate?.replaceAll("/", ".")}
                  update
                />
              </div>
            </div>
            <PostCategories categories={wpPost.categories.nodes} />
          </div>
          <div
            className="post-main__content"
            dangerouslySetInnerHTML={{ __html: wpPost.content }}
          />
          {/* <div
            className="post-main__content"
            dangerouslySetInnerHTML={{ __html: sanitize(wpPost.content) }}
          /> */}
          {wpPost?.author?.node?.acfAuthor?.postAuthorName && (
            <div className="post-main__author">
              <div className="post-main__author-inner">
                {wpPost?.author?.node?.acfAuthor?.postAuthorImage?.localFile
                  ?.publicURL && (
                  <div
                    className="post-main__author-image"
                    style={{
                      backgroundImage: `url('${wpPost?.author?.node?.acfAuthor?.postAuthorImage?.localFile?.publicURL}')`,
                    }}
                  />
                )}
                <div>
                  <p>O autorze</p>
                  <h3>{wpPost?.author?.node?.acfAuthor?.postAuthorName}</h3>
                  <small>
                    {wpPost?.author?.node?.acfAuthor?.postAuthorPosition}
                  </small>
                </div>
              </div>
              {wpPost?.author?.node?.acfAuthor?.postAuthorDescription && (
                <div className="post-main__author-description">
                  {wpPost?.author?.node?.acfAuthor?.postAuthorDescription}
                </div>
              )}
              {wpPost?.author?.node?.acfAuthor?.postAuthorLink && (
                <a
                  href={wpPost?.author?.node?.acfAuthor?.postAuthorLink}
                  className="button button--green button--small"
                >
                  Przeczytaj o autorze
                </a>
              )}
            </div>
          )}

          <PostOther category={wpPost?.categories?.nodes[0]} />

          <Divider marginTop={50} marginBottom={50} />

          <div className="row">
            <div className="col-md-6">
              <div className="post-main__button-item">
                <div className="post-main__button-text">
                  Chcesz być na bieżąco z naszymi artykułami psychologicznymi?
                </div>
                <a
                  href="https://www.facebook.com/spokojwglowie"
                  target="_blank"
                  className="button button--green"
                  rel="noreferrer nofollow"
                >
                  Polub nas na Facebook
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="post-main__button-item">
                <div className="post-main__button-text">
                  Udostępnij wpis na Facebooku, aby pomóc innym osobom!
                </div>
                <FacebookShareButton url={location}>
                  Udostępnij ten artykuł
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SinglePost;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      slug
      content
      excerpt
      uri
      title
      categories {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          avatar {
            url
          }
          username
          firstName
          lastName
          acfAuthor {
            postAuthorName
            postAuthorPosition
            postAuthorLink
            postAuthorDescription
            postAuthorImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        metaDesc
        title
      }
      date(locale: "pl", formatString: "DD.MM.YYYY")
      acfPost {
        postUpdateDate
      }
    }
  }
`;
